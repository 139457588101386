<template>
  <v-row no-gutters>
    <v-col v-if="hasValidCode == null" cols="12">
      <p class="text-center">
        <v-progress-linear color="primary" indeterminate rounded />
      </p>
    </v-col>
    <v-col v-else-if="hasValidCode" cols="12">
      <p class="text-center">
        <v-icon color="success" size="64" v-text="'mdi-check-circle-outline'" />
      </p>
      <p class="font-weight-bold text-center">
        {{ $t("auth.email_changed") }}
      </p>
    </v-col>
    <v-col v-else cols="12">
      <p class="text-center">
        <v-icon color="error" size="64" v-text="'mdi-cancel'" />
      </p>
      <p class="font-weight-bold text-center">
        {{ $t("auth.invalid_data") }}
      </p>
    </v-col>
    <v-col cols="12">
      <p class="text-center mt-8 my-0">
        <router-link
          class="error--text font-weight-black"
          :to="{ name: 'sign-in' }"
        >
          {{ $t("auth.back") }}
        </router-link>
      </p>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";

const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: { "Content-type": "application/json" }
});

export default {
  name: "VerifyEmail",

  data: () => ({
    hasValidCode: null
  }),

  created() {
    this.checkCode();
  },

  methods: {
    checkCode() {
      if (!this.$route.query.code) return (this.hasValidCode = false);

      const body = {
        code: String(this.$route.query.code)
      };

      api
        .post("email/confirm", body)
        .then(({ data }) => (this.hasValidCode = "success" in data));
    }
  }
};
</script>
